import React from 'react'

import Layout from '../components/layout'
import { Link } from 'gatsby'
import Seo from "../components/seo"


class PortfolioPage extends React.Component {
  render() {
    return (
      <Layout>
      <Seo title="Portfolio" />

      <div id="main">
          <article className="post featured">
          <div className="box">
            <em>Ca arrive bientôt...</em>
            <br/> En attendant, mes derniers projets sont présentés dans <em><Link to="/">ACCUEIL</Link></em>
            <br/>et des projets qui m'ont inspiré dans <em><Link to="/vu-ailleurs/">VU AILLEURS</Link></em>.
            </div>
          </article>


      </div>
      </Layout>
    )
  }
}

export default PortfolioPage
